<template>
<page title="专家库信息搜索">
  <commonbanner></commonbanner>
<article id="page-expert">
  <section class="container container-main">
    <div class="subtitlesear">
    <a-input-search
                        style="width: 700px"
                        placeholder="请输入专家姓名"
                        enter-button="搜索"
                        size="large"
                        v-model="expert_name"
                        @search="
                          (value) => (expert_name = value)
                        "
                      />
    </div>
    <div class="Team">
      <div class="TeamList">
        <Collection
      name="expert"
      :page="provider.current"
      :limit="provider.defaultPageSize"
      v-slot="{ collection, count }"
      :query="{ expert_name : expert_name, contact_number : contact_number}">
     <Experter :refObj="collection"></Experter>
         <div class="tabs_pagination">
              <a-pagination
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
          </div>
          </Collection>
      </div>
    </div>
  </section>
</article>
</page>
</template>
<script>
import Collection from '@/components/Collection'
import Experter from '@/components/base/Experter'
import Commonbanner from '@/components/base/Commonbanner'
export default {
  name: 'ExpertQuery',
  components: {
    Commonbanner,
    Collection,
    Experter
  },
  data () {
    var expertname = this.$route.query.name
    var number = this.$route.query.number
    return {
      lawActive: '0176141e93568a8380377603faf907ff',
      expert_name: expertname,
      contact_number: number,
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      },
      providerClass: ['宪法及相关法类', '行政法类', '民商法类', '经济法类', '社会法类', '诉讼与非诉讼程序法类'],
      public_provider_class: ['律师服务类', '公证服务类', '司法鉴定类', '法律援助类', '人民调解类', '仲裁服务类', '法治宣传类', '基层法律服务类', '法律职业资格考试类', '法律服务志愿者类', '公共法律服务理论研究类', '公共法律服务创新发展类']
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    }
  },
  activated () {
    this.typename = this.$route.params.typename
  }
}
</script>
<style scoped src="../../assets/css/expert.css"></style>
